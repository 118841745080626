var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"signosForm",attrs:{"onSubmit":"return false;"},on:{"submit":_vm.agrega},model:{value:(_vm.validaSigno),callback:function ($$v) {_vm.validaSigno=$$v},expression:"validaSigno"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Agregar signos ")]),_c('v-card-text',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.moment(_vm.moment(_vm.estaFecha, "DD-MM-YYYY")).format("dddd MMMM DD, YYYY"))+" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xs":"6","sm":"6","md":"6","lg":"6","xl":"6","cols":"12"}},[_c('v-form',{model:{value:(_vm.muestraCampos),callback:function ($$v) {_vm.muestraCampos=$$v},expression:"muestraCampos"}},[_c('v-text-field',{ref:"signoHora",attrs:{"rules":[
                                    value => !!value || 'Requerido.',
                                    value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                ],"filled":"","rounded":"","clearable":"","dense":"","label":"Hora (HH:MM 24 hrs)"},model:{value:(_vm.horaCarga),callback:function ($$v) {_vm.horaCarga=$$v},expression:"horaCarga"}})],1)],1)],1),_c('v-expand-transition',[(_vm.muestraCampos)?_c('div',{attrs:{"no-gutters":""}},[_c('v-sheet',{staticClass:"mb-2",attrs:{"elevation":"2"}},[_c('v-toolbar',{staticClass:"headline grey lighten-2 mb-2",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" Signos y contantes ")])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.signosListSomato),function(item,i){return _c('v-col',{key:i,staticClass:"px-1",attrs:{"xs":"6","sm":"6","md":"4","lg":"3","xl":"4","cols":"12"}},[(!_vm.camposTa(item.signo))?_c('v-text-field',{attrs:{"disabled":_vm.evaluaSigno(item.signo),"persistent-hint":_vm.evaluaSigno(item.signo),"hint":_vm.evaluaSigno(item.signo)?"Existe registro a esta hora":"","rules":[
                                            value => !value || (value >= item.min || 'Valor MENOR al mínimo ('+item.min+')'),
                                            value => !value || (value <= item.max || 'Valor MAYOR al máximo ('+item.max+')'),
                                        ],"type":"number","filled":"","rounded":"","clearable":"","dense":"","label":item.nombreLargo},model:{value:(_vm.datosSignos[item.signo]),callback:function ($$v) {_vm.$set(_vm.datosSignos, item.signo, $$v)},expression:"datosSignos[item.signo]"}}):_vm._e(),(_vm.camposTa(item.signo))?_c('v-text-field',{attrs:{"persistent-hint":_vm.evaluaSigno(item.signo),"hint":_vm.evaluaSigno(item.signo)?"Existe registro a esta hora":"","disabled":_vm.evaluaSigno(item.signo),"filled":"","rounded":"","clearable":"","dense":"","label":item.nombreLargo},model:{value:(_vm.datosSignos[item.signo]),callback:function ($$v) {_vm.$set(_vm.datosSignos, item.signo, $$v)},expression:"datosSignos[item.signo]"}}):_vm._e()],1)}),1)],1),_c('v-sheet',{staticClass:"mb-2",attrs:{"elevation":"2"}},[_c('v-toolbar',{staticClass:"headline grey lighten-2 mb-2",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" Parámetros de ventilador ")])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.signosListVent),function(item,i){return _c('v-col',{key:i,staticClass:"px-1",attrs:{"xs":"6","sm":"6","md":"4","lg":"3","xl":"4","cols":"12"}},[(!_vm.camposTa(item.signo))?_c('v-text-field',{attrs:{"disabled":_vm.evaluaSigno(item.signo),"persistent-hint":_vm.evaluaSigno(item.signo),"hint":_vm.evaluaSigno(item.signo)?"Existe registro a esta hora":"","rules":[
                                            value => !value || (value >= item.min || 'Valor MENOR al mínimo ('+item.min+')'),
                                            value => !value || (value <= item.max || 'Valor MAYOR al máximo ('+item.max+')'),
                                        ],"type":"number","filled":"","rounded":"","clearable":"","dense":"","label":item.nombreLargo},model:{value:(_vm.datosSignos[item.signo]),callback:function ($$v) {_vm.$set(_vm.datosSignos, item.signo, $$v)},expression:"datosSignos[item.signo]"}}):_vm._e(),(_vm.camposTa(item.signo))?_c('v-text-field',{attrs:{"persistent-hint":_vm.evaluaSigno(item.signo),"hint":_vm.evaluaSigno(item.signo)?"Existe registro a esta hora":"","disabled":_vm.evaluaSigno(item.signo),"filled":"","rounded":"","clearable":"","dense":"","label":item.nombreLargo},model:{value:(_vm.datosSignos[item.signo]),callback:function ($$v) {_vm.$set(_vm.datosSignos, item.signo, $$v)},expression:"datosSignos[item.signo]"}}):_vm._e()],1)}),1)],1)],1):_vm._e()])],1),_c('v-card-actions',[(_vm.muestraCampos)?_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":!_vm.validaSigno,"loading":_vm.loadingSigno}},[_vm._v(" Agregar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelaAgrega}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }