<template>
    <v-dialog
        v-model="dialog"
        max-width="1000"
        persistent
    >
        <v-form
            ref="signosForm"
            @submit="agrega"
            onSubmit="return false;"
            v-model="validaSigno"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Agregar signos
                </v-card-title>

                <v-card-text class="pt-5">

                    {{moment(moment(estaFecha, "DD-MM-YYYY")).format("dddd MMMM DD, YYYY")}}
                    <v-row no-gutters> 
                        <v-col xs="6" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-form v-model="muestraCampos">
                                <v-text-field
                                    ref="signoHora"
                                    v-model="horaCarga"
                                    :rules="[
                                        value => !!value || 'Requerido.',
                                        value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                    ]"
                                    filled
                                    rounded
                                    clearable
                                    dense
                                    label="Hora (HH:MM 24 hrs)"
                                ></v-text-field>

                            </v-form>
                        </v-col>
                    </v-row>

                    <v-expand-transition>
                        <div v-if="muestraCampos" no-gutters>

                            <v-sheet
                                elevation="2"
                                class="mb-2"
                            >
                                <v-toolbar class="headline grey lighten-2 mb-2" dense flat>
                                    <v-toolbar-title>
                                        Signos y contantes
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-row no-gutters>
                                    <v-col
                                        class="px-1"
                                        v-for="(item, i) in signosListSomato"
                                        :key="i"
                                        xs="6"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="4"
                                        cols="12"
                                    >

                                        <!-- Para los datos numéricos -->
                                        <v-text-field
                                            v-if="!camposTa(item.signo)"
                                            v-model="datosSignos[item.signo]"
                                            :disabled="evaluaSigno(item.signo)"
                                            :persistent-hint="evaluaSigno(item.signo)"
                                            :hint='evaluaSigno(item.signo)?"Existe registro a esta hora":""'
                                            :rules="[
                                                value => !value || (value >= item.min || 'Valor MENOR al mínimo ('+item.min+')'),
                                                value => !value || (value <= item.max || 'Valor MAYOR al máximo ('+item.max+')'),
                                            ]"
                                            type="number"
                                            filled
                                            rounded
                                            clearable
                                            dense
                                            :label="item.nombreLargo"
                                        ></v-text-field>

                                        <!-- para los datos de texto como la TA -->
                                        <v-text-field
                                            v-if="camposTa(item.signo)"
                                            :persistent-hint="evaluaSigno(item.signo)"
                                            :hint='evaluaSigno(item.signo)?"Existe registro a esta hora":""'
                                            :disabled="evaluaSigno(item.signo)"
                                            v-model="datosSignos[item.signo]"
                                            filled
                                            rounded
                                            clearable
                                            dense
                                            :label="item.nombreLargo"
                                        ></v-text-field>
                                    </v-col>

                                </v-row>

                            </v-sheet>

                            <!-- PARÁMETROS DE VENTILADOR -->

                            <v-sheet
                                elevation="2"
                                class="mb-2"
                            >
                                <v-toolbar class="headline grey lighten-2 mb-2" dense flat>
                                    <v-toolbar-title>
                                        Parámetros de ventilador
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-row no-gutters>
                                    <v-col
                                        class="px-1"
                                        v-for="(item, i) in signosListVent"
                                        :key="i"
                                        xs="6"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="4"
                                        cols="12"
                                    >

                                        <!-- Para los datos numéricos -->
                                        <v-text-field
                                            v-if="!camposTa(item.signo)"
                                            v-model="datosSignos[item.signo]"
                                            :disabled="evaluaSigno(item.signo)"
                                            :persistent-hint="evaluaSigno(item.signo)"
                                            :hint='evaluaSigno(item.signo)?"Existe registro a esta hora":""'
                                            :rules="[
                                                value => !value || (value >= item.min || 'Valor MENOR al mínimo ('+item.min+')'),
                                                value => !value || (value <= item.max || 'Valor MAYOR al máximo ('+item.max+')'),
                                            ]"
                                            type="number"
                                            filled
                                            rounded
                                            clearable
                                            dense
                                            :label="item.nombreLargo"
                                        ></v-text-field>

                                        <!-- para los datos de texto como la TA -->
                                        <v-text-field
                                            v-if="camposTa(item.signo)"
                                            :persistent-hint="evaluaSigno(item.signo)"
                                            :hint='evaluaSigno(item.signo)?"Existe registro a esta hora":""'
                                            :disabled="evaluaSigno(item.signo)"
                                            v-model="datosSignos[item.signo]"
                                            filled
                                            rounded
                                            clearable
                                            dense
                                            :label="item.nombreLargo"
                                        ></v-text-field>
                                    </v-col>

                                </v-row>
                            </v-sheet>
                        </div>
                    </v-expand-transition>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        v-if="muestraCampos"
                        color="success"
                        type="submit"
                        :disabled="!validaSigno"
                        :loading="loadingSigno"
                    >
                        Agregar
                    </v-btn>

                    <v-btn color="error" @click="cancelaAgrega">
                        Cancelar
                    </v-btn>
                </v-card-actions>


            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
export default {
    props:{
        estaFecha: String,
        dialogSignosTodos: Boolean,
        items: Array,
        registros:Array,
        internamiento:Number,
    },
    data: () => ({
        validaSigno:false,
        loadingSigno:false,
        horaCarga:'',
        mismaHora:[],
        muestraCampos:false,
        todo:false,
        datosSignos:{
            //
        },
    }),
    methods:{
        async agrega(){
            // ------------------------------------------------------------------------------
            // Utiliza async await con un for para agregar varios registros secuencialmente
            // ------------------------------------------------------------------------------
            let dt
            this.loadingSigno = true

            for(dt of Object.entries(this.datosSignos)){
                let ds = {
                    hora:this.horaCarga,
                    fecha:this.estaFecha,
                    // Revisar como podemos jalar este desde una propiedad
                    internamiento:this.internamiento,
                    signo:dt[0],
                    valor:dt[1],
                }

                if (dt[1]) {
                    try{
                        let sg = await this.$http({
                            url:'/enfermeria/guardaSignos',
                            method:'POST',
                            data: ds
                        })
                        if (sg.data.estado == false){
                            this.loadingSigno = false
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'No se puede guardar',
                                text: 'Verifique los datos e intente de nuevo',
                            })
                            this.todo = false
                            break

                        }
                    } catch(err){
                        this.loadingSigno = false
                        this.todo = false
                        console.log(err)
                        this.$store.dispatch('security/revisaError',err)
                        break
                    }
                }
                this.todo = true
            }
            this.loadingSigno = false

            if (this.todo == true){
                this.$refs.signosForm.reset()
                this.horaCarga=''
                this.$emit('signoAgregado')
                this.dialog=false
            }
        },
        camposTa(val){
            //console.log(val == 'ta' || val == 'taIn')
            return (val == 'ta' || val == 'taIn' || val == 'tipov')
        },
        cancelaAgrega(){
            this.dialog=false
            this.horaCarga=''
            this.$refs.signosForm.reset()
        },
        evaluaSigno(signo){
            //let respuesta = false
            //if (this.mismaHora.includes(signo)) {
                //let signos = this.registros.filter(ent=>ent.signo==signo)
                //let valor = signos.filter(ent=>ent.hora==this.horaCarga)
                //if (valor.length>0){
                //    this.datosSignos[signo]= valor[0].valor
                //}
                //respuesta=true
            //}
            //return (respuesta)
            return (this.mismaHora.includes(signo))
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogSignosTodos
            },
            set(value){
                //console.log(value)
                this.$emit('update:dialogSignosTodos',value)
            },
        },

        signosListSomato(){
            return (this.items.filter(ss=>ss.tipo=='s'))
        },
        signosListVent(){
            return (this.items.filter(ss=>ss.tipo=='v'))
        }
    },
    watch:{
        'datosSignos.ta'(val){
            let expresion = /\d["/"]\d/
            if(expresion.test(val)){
                let taArray = val.split("/")
                let taS = parseInt(taArray[0])
                let taD = parseInt(taArray[1])
                if (taS > taD){
                    //this.datosSignos.tam=this.$numeral((taS+(taD*2))/3).format('0.00')
                    this.datosSignos.tam=Math.round((taS+(taD*2))/3)
                }
            }
        },
        'datosSignos.taIn'(val){
            let expresion = /\d["/"]\d/
            if(expresion.test(val)){
                let taArray = val.split("/")
                let taS = parseInt(taArray[0])
                let taD = parseInt(taArray[1])
                if (taS > taD){
                    //this.datosSignos.tam=this.$numeral((taS+(taD*2))/3).format('0.00')
                    this.datosSignos.taInM=Math.round((taS+(taD*2))/3)
                }
            }
        },
        muestraCampos(){
            if (this.muestraCampos && this.horaCarga){
                let v1 = this.horaCarga.split(':')
                let hr = parseInt(v1[0], 10);

                if (hr < 10){
                    this.horaCarga = '0'+hr+':'+v1[1]
                } else {
                    this.horaCarga = v1[0]+':'+v1[1]
                }

                let tiene = []

                this.registros.forEach(dt => {
                    if (dt.hora === this.horaCarga){
                        tiene.push(dt.signo)
                    }
                })
                this.mismaHora = tiene
            }
        }
    },

}
</script>

<style>

</style>